import { useEffect, useState } from 'react';
import { randomElement } from "./Utils";

export function CursorBear() {
    const quotes = [
      'HEY BRO',
      '$BRO',
      "I'm bearish ngl",
      "bro",
      "bro?",
    ]
    const [pos, setPos] = useState([-10000, -10000]);
    const [cursorX, setCursorX] = useState(0);
    const [cursorY, setCursorY] = useState(0);
    //const [deviceType, setDeviceType] = useState('');
    const [isClicking, setIsClicking] = useState(false);
    const [quote, setQuote] = useState('');
  
    const getIsClicking = () => {
      return isClicking
    }

    const isTouchDevice = () => {
      try {
        document.createEvent('TouchEvent');
        //setDeviceType('touch');
        return true;
      } catch (e) {
        //setDeviceType('mouse');
        return false;
      }
    };
  
    const move = (e) => {
      const touchEvent = e.touches ? e.touches[0] : null;
      const x = !isTouchDevice() ? e.clientX : touchEvent?.clientX || 0;
      const y = !isTouchDevice() ? e.clientY : touchEvent?.clientY || 0; 
      setCursorX(x);
      setCursorY(y); 
    };
   
    const handleMouseDown = () => {
      setIsClicking(true);
    };
   
    const handleMouseUp = () => {
      setIsClicking(false);
    };
   
    useEffect(() => {
      document.addEventListener('mousemove', move);
      document.addEventListener('touchmove', move);
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('mouseup', handleMouseUp);
      
      return () => {
        document.removeEventListener('mousemove', move);
        document.removeEventListener('touchmove', move);
        document.removeEventListener('mousedown', handleMouseDown);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
    useEffect(() => {
      setTimeout(() => {
        if(cursorX || cursorY) {
          setPos([cursorX + 10, cursorY + 10])
        }
      }, 150)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cursorX, cursorY, pos]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setQuote(randomElement(quotes))
      }, 2000);
    }, []);
    
    return(
      <div
        className="absolute z-[20]"
        style={{ left: `${pos[0]}px`, top: `${pos[1]}px` }}
      >
        <p className="font-['Minecraft'] transition-all transform-gpu delay-100 duration-100 text-xs text-white/70 font-bold ml-8" style={{ opacity: !isClicking && cursorX + 10 === pos[0] && cursorY + 10 && pos[1] ? 1 : 0 }}>{quote}</p>
        <img src="./assets/cursor.png" alt="cursor" className={`h-8 ${isClicking ? 'animate-spin' : ''}`} draggable={false} />
      </div>
    )
  }