export function Sparkle(props) {
    return (
        <div
            className={`absolute z-[40] h-full w-full ${ props.bounce ? 'animate-bounceLow' : '' }`}
            style={{
                left: `${props.x}%`, 
                top: `${props.y}%`,
            }}
        >
            <svg 
                className={`animate-sparkle opacity-0`}
                width="18" 
                height="18" 
                viewBox="0 0 160 160" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg"
                style={{
                    animationDelay: `${props.delay}s`,
                    animationDuration: `${props.speed}s`,
                }}
            >
                <path 
                d="M80 0C80 0 84.2846 41.2925 101.496 58.504C118.707 75.7154 160 80 160 80C160 80 118.707 84.2846 101.496 101.496C84.2846 118.707 80 160 80 160C80 160 75.7154 118.707 58.504 101.496C41.2925 84.2846 0 80 0 80C0 80 41.2925 75.7154 58.504 58.504C75.7154 41.2925 80 0 80 0Z"
                fill='#F0DF00'
                />
            </svg>
        </div>
    );
  }