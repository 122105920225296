export function Button(props) {
    const color = props.color ? `${props.color}` : '#FFFFFF10'
    return(
        <div 
            className={`
                md:backdrop-blur-lg 
                pointer
                transition-all transform-gpu ease-out duration-100 
                flex flex-col justify-center gap-1 items-center 
                m-auto 
                shadow-md 
                rounded-xl 
                p-2
                hover:brightness-150 active:brightness-200 
            `}
            style={{
                backgroundColor: color
            }}
            onClick={() => { props.handler(props.data) }}
            >
                <p className="pointer-events-none font-bold text-center capitalize text-xs">{props.text}</p>
        </div>
    )
}