import { motion } from "framer-motion"
import { useState, useEffect } from "react";
import eventBus from "../components/EventBus";
import {
    Area,
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Legend,
    ResponsiveContainer,
  } from "recharts";

function Chart(props) {
    const [data, setData] = useState([
        {
          uv: 0,
          amt: 0,
        },
    ]);
    const formatter = (value) => `${parseFloat(value.toFixed(3))}`;

    useEffect(() => {
        const _data = [...data]
        _data.push({
            uv: props.score,
            amt: data.length
        });
        setData(_data);
    }, [props.score]);
    
    useEffect(() => {
    }, [data]);
    
    return(
        <div className="w-5/6 md:aspect-square p-4 flex justify-center items-center text-white">
            <ResponsiveContainer>
                <ComposedChart
                    width={550}
                    height={400}
                    data={data}
                >
                    <defs>
                        <linearGradient
                            id="gradient-positive"
                            x1="1"
                            y1="1"
                            x0="0"
                            y0="0"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stopColor="#0A1D4D" />
                            <stop offset="80%" stopColor="#256CDD" stopOpacity="0.95" />
                        </linearGradient>
                    </defs>
                    <CartesianGrid strokeDasharray="5 5" stroke="#84AAD850" />
                    <YAxis tickFormatter={formatter} />
                    <Line
                        type="monotone"
                        strokeLinecap="round"
                        stroke="#6185F6"
                        dataKey="uv"
                        style={{
                            filter: `drop-shadow(0px 8px 8px rgba(95, 135, 243, 0.9)) drop-shadow(0px -2px 8px rgba(35, 236, 171, 0.9))`,
                        }}
                        strokeWidth={"5px"}
                        dot={false}
                    />
                    <Area
                        type="monotone"
                        dataKey="uv"
                        stroke="#23ECAB"
                        strokeWidth={1.5}
                        fill={`url(#gradient-positive)`}
                    />
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
};

function GameDiv(props) {
    const variants = {
        open: {
          y: 0,
          opacity: 1,
          transition: {
            y: { stiffness: 1000, velocity: -100 }
          }
        },
        closed: {
          y: 50,
          opacity: 0,
          transition: {
            y: { stiffness: 1000 }
          }
        }
    };
    return(
        <div className="h-fit md:h-full bg-[#f0f0f006] relative flex flex-col justify-center items-center rounded-xl overflow-hidden">
            <div className="p-2 h-full w-full flex md:flex-col justify-evenly md:justify-center items-center gap-2 md:gap-4">
                <div className="w-full flex flex-col justify-center items-center">
                    <div 
                        className={`
                            flex flex-col justify-evenly items-center 
                            w-fit 
                            m-auto
                            p-[2px]
                            text-center
                            rounded-xl
                            tailedDiv
                            relative inline-block
                            z-[35]
                        `}
                    >
                        <div className="p-1 md:p-4 w-full bg-black rounded-lg shadow-inner overflow-hidden z-[36] text-nowrap">
                            <p className="text-[0.6rem] md:text-base">Coins: {props.score.toFixed(5)}</p>
                            <p className="text-[0.5rem] md:text-xs font-light">({props.ratio.toFixed(3)}/s)</p>
                        </div>
                    </div>
                </div>
                <div className="w-full h-fit md:w-3/6 md:aspect-square flex justify-evenly items-center relative z-[33]" onClick={props.handleClickCoin}>
                    <motion.div
                        whileHover={{ scale: 0.85 }} whileTap={{ scale: 0.75 }} variants={variants}
                        className="z-[34] w-full cursor-pointer flex justify-evenly items-center"
                        style={{scale: 0.8}}
                    >
                        <img loading="lazy" alt='btc-img' src='./assets/stocks/btc.png' className='pointer-events-none h-full w-full animate-wiggle' draggable={false} />
                    </motion.div>
                    <img loading="lazy" alt='shine-img' src='./assets/stocks/shine.png' className='z-[33] absolute h-full w-full pointer-events-none animate-shine' draggable={false} />
                    <img loading="lazy" alt='shine-img' src='./assets/stocks/shine.png' className='z-[33] absolute h-full w-full pointer-events-none animate-shine2' draggable={false} />
                </div>
            </div>
            <img loading="lazy" alt='border-img' src='./assets/stocks/shadedBordersSoft.png' className='z-[32] absolute pointer-events-none w-full h-full' draggable={false} />
            <img loading="lazy" alt='star-img' src='./assets/stocks/starbg.jpg' className='z-[31] absolute pointer-events-none w-full h-full opacity-10' draggable={false} />
        </div>
    )
}

function ShopItem(props) {
    const [totalPrice, setTotalPrice] = useState(0)
    const [canBuy1, setB1] = useState(false)
    const [canBuy10, setB10] = useState(false)

    const computePrice = (qty) => {
        let _totalPrice = 0
        for(let i = 0; i < qty; i++) {
            _totalPrice += props.item.price * Math.pow(1.005, parseInt(props.owned) + i)
        } 
        return parseFloat(_totalPrice).toFixed(5)
    }

    useEffect(() => {
        const b1 = computePrice(1)
        const b10 = computePrice(10)
        setB1( props.score >= b1 )
        setB10( props.score >= b10 )
        setTotalPrice(b1)
    }, [totalPrice, canBuy1, canBuy10, props.score, props.item])


    return(
        <div className="text-nopwrap rounded-lg p-1 flex justify-center items-center gap-1 w-full h-full m-auto">
            <div className="bg-white/10 py-2 px-2 rounded-lg flex flex-auto h-full justify-start items-center gap-2">
                <img loading="lazy" alt='item-img' src={props.item.img} className="h-10 aspect-square rounded-full border-2 border-white/10"/>
                <div className="w-full flex flex-col md:flex-row justify-evenly md:justify-between items-start md:items-center px-2">
                    <p className="text-[0.7rem] font-bold">{props.item.name}</p>
                    <div className="flex flex-auto text-nowrap flex-col justify-start items-start md:items-end font-light text-[0.65rem]">
                        <div className="flex justify-start items-baseline gap-1">
                            <p>Price:</p>
                            <p>{totalPrice} BTC</p>
                        </div>
                        <div className="flex justify-start items-baseline gap-1">
                            <p>Mines:</p>
                            <p>{props.item.rate} BTC/s</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col justify-evenly items-stretch h-full gap-1">
                <div 
                    className={`
                        transition-all transform-gpu duration-300 ease-out
                        bg-white/10 hover:bg-white/20 active:bg-white/30 
                        rounded-lg p-1
                        flex justify-center items-center w-16 h-full
                        cursor-pointer
                        text-xs
                        ${ !canBuy1 ? 'cursor-not-allowed grayscale-50 opacity-60 hover:bg-white/10 active:bg-white/10' : '' }
                    `}
                    onClick={() => { if(canBuy1) props.handleClickItem(props.item, 1, computePrice(1)) } }
                >
                    Buy 1
                </div>
                <div 
                    className={`
                        transition-all transform-gpu duration-300 ease-out
                        bg-white/10 hover:bg-white/20 active:bg-white/30 
                        rounded-lg p-1
                        flex justify-center items-center w-16 h-full
                        cursor-pointer
                        text-xs
                        ${ !canBuy10 ? 'cursor-not-allowed grayscale-50 opacity-60 hover:bg-white/10 active:bg-white/10' : '' }
                    `}
                    onClick={() => { if(canBuy10) props.handleClickItem(props.item, 10, computePrice(10)) } }
                >
                    Buy 10
                </div>
            </div>
        </div>
    )
}

function OwnedItem(props) {
    return(
        <div 
            className={`
                transition-all transform-gpu duration-300 ease-out
                w-full
                md:text-nopwrap
                grid grid-cols-3 justify-evenly items-center
                text-center
                text-[0.6rem] md:text-xs
                md:font-semibold
                md:text-nowrap
            `}
        >
            <p className="w-full h-fit m-auto text-center md:overflow-hidden">{props.item.name}</p>
            <p className="w-full h-fit m-auto text-center md:overflow-hidden">Owns: {props.item.count}</p>
            <p className="w-full h-fit m-auto text-center md:overflow-hidden">Mining: {props.item.count * props.item.rate} BTC/s</p>
        </div>
    )
}

function Shop(props) {
    const [parsedInventory, setParsedInventory] = useState({})

    useEffect(() => {
        const parsed = Object.values(props.inventory.reduce((acc, item) => {
            const key = `${item.name}-${item.img}-${item.price}-${item.rate}`;
            if (!acc[key]) {
                acc[key] = { ...item, count: 1 };
            } else {
                acc[key].count += 1;
            }
            return acc;
        }, {}));
        setParsedInventory({...parsed})
    }, [props.inventory])
    
    useEffect(() => {
    }, [props.score, parsedInventory])

    return(
        <div className="m-auto w-full md:w-5/6 h-fit md:h-5/6 md:max-h-[70vh] flex flex-col justify-between items-center text-sm rounded-lg bg-white/5 p-4">
            <div className="w-full flex flex-col justify-start items-center gap-2 overflow-auto p-2">
                <p className="text-gray-500">SHOP</p>
                <div className="w-full grid grid-cols-1 gap-4 justify-evenly items-center content-center">
                    {
                        props.items.map((item, index) => {
                            return <ShopItem 
                                        key={index}
                                        index={index}
                                        score={props.score}
                                        item={item}
                                        owned={parsedInventory[parseInt(Object.keys(parsedInventory).filter((entry, index) => parsedInventory[index]?.name === item.name).pop())]?.count ?? 0}
                                        handleClickItem={props.handleClickItem}
                                    />
                        })
                    }
                </div>
            </div>
            <div className="w-full h-2/6 flex flex-col justify-start items-center gap-2 overflow-auto p-2">
                <div className="h-[1px] w-full bg-white/10 "></div>
                <div className="flex flex-col justify-start items-center w-full h-full">
                    <p className="text-gray-500">INVENTORY</p>
                    <div className="px-2 w-full grid grid-cols-1 justify-evenly items-center">
                        {
                            Object.keys(parsedInventory).map((itemName, index) => {
                                return <OwnedItem key={index} index={index} item={parsedInventory[itemName]} />
                            })
                        }
                        {
                            !Object.keys(parsedInventory).length
                                ? <p className="text-center m-auto text-xs font-mono opacity-60">Empty</p>
                                : ''
                        }
                    </div>
                </div>
            </div>
        </div>
    )
};

export function Market(props) {
    const [volume, setVolume] = useState(0);
    const [mc, setMc] = useState(0);
    const [ticks, setTicks] = useState(0);
    const [holders, setHolders] = useState(0);

    const formatter = (value) => `${parseFloat(value.toFixed(3))}`;
    function formatNumber(num) {
        if (num >= 1_000_000) {
            return (num / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'm';
        } else if (num >= 1_000) {
            return (num / 1_000).toFixed(1).replace(/\.0$/, '') + 'k';
        }
        return num.toFixed(5);
    }

    useEffect(() => {
        setVolume(props.score * 21000000 / 24)
        if(mc < 1000000000 && props.score *21000000 > 1000000000) eventBus.dispatch("unlock", { id: "happycasey" });
        if(props.score * 21000000 !== mc) {
            let rng = Math.floor(Math.random() * (30 - -10 + 1) + -10)
            let _holders = holders + rng
            if(_holders < 0) _holders = 0
            setHolders(_holders)
        }
        setMc(props.score * 21000000)
        setTicks(ticks+1)
    }, [props.score]);

    return(
        <div className="m-auto w-fit grid grid-cols-2 grid-rows-2 justify-center items-center gap-2 text-sm">
            <div className="flex flex-col justify-center items-start">
                <p className="text-gray-500">24H VOLUME</p>
                <p className="text-white/80">${formatNumber(volume)}</p>
            </div>
            <div className="flex flex-col justify-center items-start">
                <p className="text-gray-500">TOTAL MARKETCAP</p>
                <p className="text-white/80">${formatNumber(mc)}</p>
            </div>
            <div className="flex flex-col justify-center items-start">
                <p className="text-gray-500">TICKS</p>
                <p className="text-white/80">{ticks}</p>
            </div>
            <div className="flex flex-col justify-center items-start">
                <p className="text-gray-500">HOLDERS</p>
                <p className="text-white/80">{holders}</p>
            </div>
        </div>
    )
};

export function MarketDiv(props) {
    return(
        <div className="h-fit md:my-auto flex flex-col justify-center items-center gap-2 py-2 px-8 relative rounded-xl overflow-hidden">
            <div className="pointer-events-none w-full h-full absolute top-[-50%] inset-x-0 genii-glow scale-[2]"></div>
            <div className="flex flex-row justify-evenly gap-2">
                <div className="w-4/6 md:w-32 flex justify-center items-center">
                    <img loading="lazy" alt='ord-logo' src='./assets/stocks/ord.png' className="w-full m-auto p-1" />
                </div>
                <div className="flex flex-col justify-start items-start gap-2">
                    <div className="flex justify-start items-center gap-2">
                        <p className="uppercase text-2xl">Runes</p>
                        <div className="cursor-pointer w-8 h-8 rounded-lg bg-white/10 hover:bg-white/20 active:bg-white/30 transition-all transform-gpu p-2" onClick={ () => { window.open('https://x.com/rodarmor', '_blank') } }>
                            <img loading="lazy" alt='x-img' src='./assets/stocks/x.png' className="invert opacity-70 w-full h-full"/> 
                        </div>
                        <div className="cursor-pointer w-8 h-8 rounded-lg bg-white/10 hover:bg-white/20 active:bg-white/30 transition-all transform-gpu p-2" onClick={ () => { window.open('https://docs.ordinals.com/runes.html', '_blank') } }>
                            <img loading="lazy" alt='book-img' src='./assets/stocks/book.png' className="invert opacity-70 w-full h-full"/> 
                        </div>
                    </div>
                    <p className="text-xs text-justify">A UTXO-based fungible token protocol with good user experience proposed by Casey Rodarmor, aiming to integrate seamlessly with Bitcoin's infrastructure.</p>
                </div>
            </div>
            <Market score={props.score}/>
            <Chart score={props.score}/>
        </div>
    )
};

export function Stocks(props) {
    const items = [
        { name: 'Tweet from @runesdev', img: './assets/stocks/1.png', price: 0.01, rate: 0.000667  },
        { name: 'Airdrop Runestones', img: './assets/stocks/2.png', price: 0.02, rate: 0.0001334  },
        { name: 'Etch Pump n Dumps', img: './assets/stocks/3.png', price: 0.03, rate: 0.0002  },
        { name: 'Random Puppets/Nodes drama', img: './assets/stocks/4.png', price: 0.0420, rate: 0.0003  },
        { name: 'New innovative banger from @Bootoshi', img: './assets/stocks/5.png', price: 0.069, rate: 0.0025  },
        { name: 'New Tier list from @shin_raton', img: './assets/stocks/6.png', price: 0.1, rate: 0.005  },
    ]
    const [score, setScore] = useState(0)
    const [ratio, setRatio] = useState(0)
    const [inventory, setInventory] = useState([])

    const handleClickCoin = () => {
        setScore(score+0.001)
    }

    const handleClickItem = (item, qty, price) => {
        if(score-price > 0) {
            setScore(score-price)
            const newInventory = [...inventory]
            for(let i=0; i<qty; i++) {
                newInventory.push({...item})
            }
            setInventory(newInventory)
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            let _ratio = 0
            for(const item of inventory) {
                _ratio += item.rate
            }
            if(score < 1 && score + _ratio > 1) eventBus.dispatch("unlock", { id: "bearillionaire" });
            setScore(score + _ratio)
            setRatio(_ratio)
        }, 1000);
        return () => clearInterval(interval);
    }, [score, inventory])


    return(
        <div className="z-30 flex flex-col justify-start items-center w-[90vw] h-[100vw] md:h-fit bg-[#0E0E0F] genii overflow-auto md:overflow-hidden">
            <div className="w-2/6 md:w-64 flex justify-evenly items-center p-2">
                <img loading="lazy" alt='ord-logo' src='./assets/stocks/beariidata.png' className="w-full h-full m-auto" />
            </div>
            <div className="w-full grid grid-cols-1 md:grid-rows-1 md:grid-cols-3 justify-start items-start">
                <GameDiv ratio={ratio} score={score} handleClickCoin={handleClickCoin} />
                <Shop score={score} handleClickItem={handleClickItem} inventory={inventory} items={items}/>
                <MarketDiv score={score} inventory={inventory} />
            </div>
        </div>
    )
}