
export function Window(props) {
    return(
        <div 
            className="z-30 shadow-xl font-['Andale']" 
        >
            <div
                className="bg-[#3B4045] text-[#AEB2B6] flex justify-between items-center p-1 rounded-t-xl border-t-[1px] border-l-[1px] border-r-[1px] border-[#797A7D] outline outline-[1px] outline-black"
            >
                <div className="flex justify-start items-center gap-2 p-[4px]">
                    <div className="aspect-square w-[0.7rem] rounded-full bg-[#FF5F58] hover:brightness-150 active:brightness-200	transition-all transform-gpu duration-100 pointer" onClick={() => { try{ props.setMenu(null) } catch(e) {/**/} }}></div>
                    <div className="aspect-square w-[0.7rem] rounded-full bg-[#FEBC2E] hover:brightness-150 active:brightness-200	transition-all transform-gpu duration-100 pointer" onClick={() => { try{ props.setMenu(null) } catch(e) {/**/} }}></div>
                    <div className="aspect-square w-[0.7rem] rounded-full bg-[#28C840] hover:brightness-150 active:brightness-200	transition-all transform-gpu duration-100 pointer" onClick={() => { try{ props.setMenu(null) } catch(e) {/**/} }}></div>
                </div>
                <p className="text-xs font-bold text-center text-nowrap w-2/6">
                    {props.title}
                </p>
                <div className="w-2/6"></div>
            </div>
            <div className="mt-[1px] bg-[#292E39] text-[#EFF0EB] font-bold rounded-b-xl border-b-[1px] border-l-[1px] border-r-[1px] border-[#797A7D] outline outline-[1px] outline-black overflow-hidden">
                {props.children}
            </div>
        </div>
    )
}  