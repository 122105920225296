import { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import { Gummy } from "./Gummy";

export function Ship(props) {
    const [x, setX] = useState(Math.floor(Math.random() * (99 - 1 + 1) + 1))
  
    useEffect(() => {
      const intervalId = setInterval(() => {  //assign interval to a variable to clear it.
        setX(Math.floor(Math.random() * (99 - 1 + 1) + 1))
      }, 5000)
      return () => clearInterval(intervalId); //This is important   
    }, [])
  
    return(
      <div
        className="z-30 m-auto absolute bottom-0 h-12 w-12 md:h-32 md:w-32 animate-upOnly" 
      >
        <motion.div
          drag 
          className="w-full h-full relative" 
          style={{
            left: `${x}%`,
          }}
          >
            <div className='animate-wiggle absolute bottom-0 left-0 right-0 m-auto z-[31] hover:drop-shadow-interactive'>
              <Gummy id={4} size={'1.75rem'} left={50} />
            </div>
            <img loading="lazy" src={`./assets/ship${props.ship}.png`} alt="bro" className="h-full w-full animate-wiggle hover:drop-shadow-interactive" draggable={false} />
        </motion.div>
      </div>
    )
  }
  
  