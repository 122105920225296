import { useEffect, useState } from "react"
import downloadjs from 'downloadjs';
import html2canvas from 'html2canvas-pro';
import { randomInt } from "../components/Utils";
import eventBus from "../components/EventBus";
import { Button } from "../components/Button";
import { Gummy } from "../components/Gummy";


function TraitButton(props) {
    return(
        <div 
            className={`
                md:backdrop-blur-lg 
                pointer 
                transition-all transform-gpu ease-out duration-100 
                flex flex-col justify-center gap-1 items-center 
                m-auto 
                hover:bg-white/20 active:bg-white/30 
                shadow-md 
                rounded-xl 
                p-2
                ${ props.selected === props.type ? 'bg-white/35' : 'bg-white/10' }
            `}
            onClick={() => { props.handleSelect(props.type) }}
            >
                <p className="font-bold text-center capitalize text-xs">{props.type}</p>
        </div>
    )
}

function TraitItem(props) {
    return(
        <div 
            className={`
                md:backdrop-blur-lg 
                pointer 
                transition-all 
                transform-gpu ease-out duration-100 
                flex flex-col justify-center gap-1 items-center 
                m-auto 
                shadow-md 
                rounded-xl 
                aspect-square w-full
                bg-white/20 md:hover:bg-white/30 md:active:bg-white/40 
            `}
            onClick={() => { props.handler(props.type, props.index) }}
        >
            <img draggable={false} alt={`img-${props.type}-${props.index}`} className="z-[53] absolute h-full w-full pixel" src={`./assets/pfp/${props.type}/${props.index + 1}.png`} />
        </div>
    )
}

export function PfpGenerator(props) {
    const specialHat = [18]
    const specialBody = [0, 1, 2]
    const traitsCount = {
        'bears': 2,
        'hats': 25,
        'clothes': 20,
        'hands': 29,
        'backs': 52,
    }
    const defaultTrait = 'hats'
    const [selected, setSelected] = useState(defaultTrait);
    const [traitCount, setTraitCount] = useState(traitsCount[defaultTrait]);

    const [noise, setNoise] = useState(true);
    const handleNoise = (e) => {
        setNoise(!noise)
    }
    
    const [hat, setHat] = useState(9999)
    const [clothes, setClothes] = useState(9999)
    const [hand, setHand] = useState(9999)
    const [back, setBack] = useState(9999)
    const [bear, setBear] = useState(0)


    const handleSelect = async (trait) => {
        setSelected(trait)
        setTraitCount(traitsCount[trait])
    }

    const getRandomTrait = (type) => {
        let rng = 0;
        while(1) {
            if(type === 'hats') {
                rng = randomInt(0, traitsCount['hats']-1)
                if(!specialHat.includes(rng)) break;
            } else if(type === 'clothes') {
                rng = randomInt(0, traitsCount['clothes']-1)
                if(!specialBody.includes(rng)) break;
            } else if(type === 'hands') {
                rng = randomInt(0, traitsCount['hands']-1)
                break;
            } else if(type === 'backs') {
                rng = randomInt(0, traitsCount['backs']-1)
                break;
            }
        }
        return rng;
    }

    const handleTraitSelect = async (type, trait) => {
        if(type === 'hats') {
            setHat(9999)
            if(hat !== trait) {
                if(specialHat.includes(trait) && specialBody.includes(clothes)) setClothes(getRandomTrait('clothes'))
                setTimeout(() => {
                    if(specialHat.includes(trait)) setBear(0);
                    setHat(trait)
                }, 150)
            }
        } else if(type === 'clothes') {
            setClothes(9999)
            if(clothes !== trait) {
                if(specialHat.includes(hat) && specialBody.includes(trait)) setHat(getRandomTrait('hats'))
                setTimeout(() => {
                    if(specialBody.includes(trait)) setBear(0);
                    setClothes(trait)
                }, 150)
            }
        } else if(type === 'hands') {
            setHand(9999)
            if(hand !== trait) {
                setTimeout(() => {
                    setHand(trait)
                }, 150)
            }
        } else if(type === 'backs') {
            if(back !== trait) {
                setBack(trait)
            }
        } else if(type === 'bears') {
            if(bear !== trait) {
                if(trait === 1) {
                    if(specialHat.includes(hat)) setHat(getRandomTrait('hats'))
                    if(specialBody.includes(clothes)) setClothes(getRandomTrait('clothes'))
                }
                setBear(trait)
            }
        }
    }

    const handleDownload = async () => {
        const canvas = await html2canvas(document.getElementById('pfp-canvas'), {
            backgroundColor: null,
            windowWidth: 1024,
            windowHeight: 1024,
        });
        const dataURL = canvas.toDataURL('image/png');
        downloadjs(dataURL, 'bro-pfp', 'image/png');
        eventBus.dispatch("unlock", { id: "realbro" })
    };
        
    const generateRandom = () => {
        generateNaked()
        setTimeout(() => {
            setHat(getRandomTrait('hats'));
        }, 250)
        setTimeout(() => {
            setClothes(getRandomTrait('clothes'));
        }, 500)
        setTimeout(() => {
            setHand(getRandomTrait('hands'));
        }, 750)
    }
    
    const generateNaked = () => {
        setHat(9999);
        setClothes(9999);
        setHand(9999);
        setBack(9999);
        setBack(getRandomTrait('backs'));
    }

    useEffect(() => {
        generateRandom()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(hat === 18) eventBus.dispatch("unlock", { id: "worldpeace" })
            else if(hat === 17) eventBus.dispatch("unlock", { id: "billy" })
        if(hand === 8) eventBus.dispatch("unlock", { id: "saikopath" })
            else if(hand === 13) eventBus.dispatch("unlock", { id: "friendlypanda" })
    }, [bear, hat, hand, back, noise, selected])

    return(
        <div className="w-[95vw] md:w-auto md:h-[50vh] h-[70vh] flex flex-col md:flex-row justify-evenly gap-2 md:gap-12 p-4 md:p-8">
            <div className="h-full m-auto flex flex-col justify-between items-center p-4 gap-4 border-[1px] border-white/10 rounded-lg border-dashed md:min-w-32">
                <div className="grid grid-cols-3 md:grid-cols-4 gap-2 justify-evenly items-center top-0">
                    <TraitButton type={'hats'} sselected={selected} handleSelect={handleSelect} />
                    <TraitButton type={'clothes'} selected={selected} handleSelect={handleSelect} />
                    <TraitButton type={'hands'} selected={selected} handleSelect={handleSelect} />
                    <TraitButton type={'backs'} selected={selected} handleSelect={handleSelect} />
                    <TraitButton type={'bears'} selected={selected} handleSelect={handleSelect} />
                </div>

                <div className="relative flex-auto h-32 md:h-auto w-full p-4 rounded-lg bg-white/5 md:backdrop-blur-lg grid grid-cols-4 md:grid-cols-4 justify-start items-start overflow-auto gap-4">
                {
                    [...Array(traitCount).keys()].map((index) => {
                        return <TraitItem key={index} index={index} type={selected} handler={handleTraitSelect} />
                    })
                }
                {
                    selected === 'clothes'
                        ? <div className="h-[1.75rem] aspect-square absolute bottom-0 right-0">
                            <Gummy id={7} size={'1.75rem'} />
                        </div>
                        : ''
                }
                </div>

                <div className="flex flex-col gap-4 items-center justify-evenly">
                    <div className="flex gap-2 justify-center col-span-2">
                        <input type="checkbox" className="flex" defaultChecked={noise} onChange={(e) => { handleNoise(e) }} />
                        <label className="text-xs font-['Andale']">Add noise ?</label>
                    </div>
                    <div className="flex gap-4 justify-center col-span-2">
                        <Button 
                            text={'Reset'}
                            handler={() => { generateNaked() }}
                        />
                        <Button 
                            text={'Random'}
                            handler={() => { generateRandom() }}
                        />
                        <Button 
                            text={'Download'}
                            color={'#2860D3'}
                            handler={() => { handleDownload() }}
                        />
                    </div>
                </div>
            </div>
            <div className="z-[50] relative bg-transparent h-48 w-48 md:w-auto md:h-full aspect-square pixel m-auto p-0 flex justify-center items-center overflow-hidden" id="pfp-canvas">
                <img draggable={false} alt="img-noise" className="z-[59] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 noise" src={`./assets/pfp/noise.png`} style={{ opacity: noise ? 0.15 : 0 }} />
                <img draggable={false} alt="img-hats" className="z-[54] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 pixel" src={hat === 9999 ? './assets/pfp/void.png' : `./assets/pfp/hats/${hat+1}.png`} style={{ opacity: hat != 9999 ? 1 : 0 }} />
                <img draggable={false} alt="img-clothes" className="z-[53] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 pixel" src={clothes === 9999 ? './assets/pfp/void.png' : `./assets/pfp/clothes/${clothes+1}.png`} style={{ opacity: clothes != 9999 ? 1 : 0 }}/>
                <img draggable={false} alt="img-bears" className="z-[52] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 pixel" src={`./assets/pfp/bears/${bear+1}.png`}/>
                <img draggable={false} alt="img-hands" className="z-[54] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 pixel" src={hand === 9999 ? './assets/pfp/void.png' : `./assets/pfp/hands/${hand+1}.png`} style={{ opacity: hand != 9999 ? 1 : 0 }} />
                <img draggable={false} alt="img-backgrounds" className="z-[51] absolute h-full w-full transition-all transform-gpu ease-in-out duration-300 pixel" src={`./assets/pfp/backs/${back+1}.png`}/>
            </div>
        </div>
    )
}