import { useEffect, useState } from 'react';
import eventBus from "./EventBus";

function CurtainUnit(props) {
    useEffect(() => {}, [props.left])
    return(
        <div 
            className={`
                h-full w-8 
                bg-gradient-to-b from-white/30 to-white/10 
                md:backdrop-blur-lg 
                shadow-inner
                transition-all transform-gpu ease-in-out
                duration-1000
                absolute
                pointer-events-none
            `} 
            style={{ 
                left: `${props.left}%`,
                animationDelay: `${props.index * 1000}ms`
            }}
        ></div>
    )
}

function Curtain(props) {
    useEffect(() => {}, [props.curtainState])
    return(
        <div onClick={props.handleCurtain} className="z-[2] absolute h-96 aspect-square flex pointer">
            <div className="absolute top-0 w-full m-auto h-2 bg-[#B37F39] flex justify-between items-center z-[3]">
                <div className="rounded-full aspect-square h-4 bg-[#B37F39]"></div>
                <div className="rounded-full aspect-square h-4 bg-[#B37F39]"></div>
            </div>
            {
                [...Array(16).keys()].map((index) => {
                    return <CurtainUnit key={index} index={index} left={ props.curtainState ? 0 : index * 6.25 } />
                })
            }
        </div>
    )
}

export function THRKHDE() {
    const [enabled, setEnabled] = useState(false)
    const [curtainState, setCurtainState] = useState(false)

    const handleCurtain = () => {
        if(!enabled) return
        setCurtainState(!curtainState)
        eventBus.dispatch("unlock", { id: "seenthings" })
    }

    useEffect(() => {}, [curtainState])
    
    useEffect(() => {
        setTimeout(() => { setEnabled(true) }, 5000)
    }, [])
    return(
        <div
            className="z-[1] pointer-events-none md:pointer-events-auto animate-opacityInHidden absolute m-auto left-0 top-0 right-0 bottom-0 h-[inherit] w-[inherit] flex justify-center items-center" 
            id="try_changing_the_z-index_bro" 
        >
            <Curtain handleCurtain={handleCurtain} curtainState={curtainState} />
            <img 
                loading="lazy"
                draggable={false} 
                src="./assets/hi_baby.jpg" 
                alt="xzkcxqkllnfvlqef" 
                className="z-[1] aspect-square h-96" 
            />
        </div>
    )
}


