import { motion } from "framer-motion"
import { Window } from './Window';

const links = [
    'https://x.com/btcbrobear/status/1855931911350223306', // 0
    'https://x.com/btcbrobear/status/1855931911350223306', // 1
    'https://x.com/btcbrobear/status/1855931911350223306', // 2
    'https://x.com/btcbrobear/status/1855931911350223306', // 3
    'https://x.com/btcbrobear/status/1855931911350223306', // 4
    'https://x.com/btcbrobear/status/1856712074119819333', // 5
    'https://x.com/btcbrobear/status/1855322535379997080', // 6
    'https://x.com/btcbrobear/status/1855759749662286082', // 7
    'https://x.com/btcbrobear/status/1851451082188194273', // 8
]
export function Popup(props) {
    return(
        <motion.div 
            drag
            className="absolute z-40 aspect-square max-h-24 md:max-h-52 cursor-move hover:drop-shadow-interactive transition-all transform-gpu duration-100 ease-out" 
            style={{ 
                height: `${props.size}rem`, 
                top: props.top ?? 'unset', 
                left: props.left ?? 'unset', 
                bottom: props.bottom ?? 'unset', 
                right: props.right ?? 'unset', 
                touchAction: "none"
            }}
        >
            <Window 
                title={props.title} 
                className="h-auto w-auto flex justify-center items-center z-29 relative"
            >
                <img 
                    loading="lazy"
                    src={`./assets/gifs/${props.gif}.gif`} 
                    alt="popup" 
                    draggable={false}
                    className="h-full w-full pixel select-none" 
                    onClick={() => { window.open(links[props.gif], '_blank') }}
                />
            </Window>
        </motion.div>
    )
}  