import { useEffect, useState } from "react";
import { AchievementController } from "../components/AchievementController";
import { Gummy } from "../components/Gummy";

export function AchievementEntry(props) {
    return(
        <div className="w-28 md:w-32 aspect-[7/12] flex flex-col justify-evenly items-start content-start px-2 py-1 md:px-4 md:py-2 gap-2 md:gap-4 border-2 border-white/40 drop-shadow-lg rounded-xl bg-gradient-to-b from-white/30 to-white/10 md:backdrop-blur-lg">
            <img loading="lazy" draggable={false} alt="achievement-img" src={`./assets/trophies/${props.achievement.rarity}.png`} className="absolute left-2 top-2 drop-shadow-lg h-4 aspect-square" />
            <div className="overflow-hidden mx-auto h-12 md:h-16 aspect-square rounded-full border-2 border-white/40 drop-shadow-xl">
                {
                    props.unlocked
                        ? <img loading="lazy" draggable={false} alt="achievement-img" src={`./assets/achievements/${props.achievement.id}.png`} className="shadow-inner h-full w-full" />
                        : <img loading="lazy" draggable={false} alt="achievement-img" src="./assets/achievements/locked.png" className="shadow-inner h-full w-full" />
                    }
            </div>
            <div className="col-span-2 md:col-span-1 grid grid-rows-2 justify-evenly items-center gap-1 text-center md:w-full max-h-20">
                <p className="font-bold uppercase text-center text-[0.55rem] md:text-xs">{props.achievement.name}</p>
                {
                    props.unlocked
                        ? <p className="text-[0.5rem] md:text-[0.55rem] text-center text-white/60">{props.achievement.caption}</p>
                        : <p className="text-[0.5rem] md:text-[0.55rem] text-center text-white/60">{props.achievement.hint} {props.achievement.id === 'gummyhunter' ? `(${JSON.parse(localStorage.getItem('gummies'))?.length ? 8-JSON.parse(localStorage.getItem('gummies'))?.length : 8} left)` : ''}</p>
                }
            </div>
        </div>
    )
}
export function Achievements(props) {
    const [achievements, setAchievements] = useState([]);
    const [pct, setPct] = useState(0);
    const achievementController = new AchievementController();

    useEffect(() => {
        const _achievements = achievementController.getAchievements();
        setAchievements(_achievements)
        const intervalId = setInterval(() => {
            const _achievements = achievementController.getAchievements();
            if(_achievements.length !== achievements.length) setAchievements([..._achievements])
        }, 5000)
        return () => clearInterval(intervalId);
    }, [])
    
    useEffect(() => {
        setPct(Math.round(achievements.filter((entry) => entry.unlocked).length * 100 / achievements.length))
    }, [achievements])

    return(
        <div className="z-30 w-full flex flex-col justify-evenly p-2 md:p-4 gap-2 md:gap-4 bg-[#1E2021] font-mono relative">
            <div className="w-full flex flex justify-between items-center gap-4 md:gap-16">
                <p className="font-bold text-sm md:text-xl ">Bro Achievements</p>
                <div className="flex justify-evenly items-stretch content-end gap-4">
                    <div className="grid grid-cols-1 grid-rows-2 gap-1">
                        <p className="h-fit m-auto text-xs">Progress</p>
                        <div className="flex flex-col h-full">
                            <p className="text-xs md:text-lg">{ pct }%</p>
                            <div className="h-1 w-full bg-white/10 rounded-xl">
                                <div className="h-full bg-white/80 rounded-xl transition-all transform-gpu ease-in-out duration-300" style={{ width: `${pct}%` }}></div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 grid-rows-2 gap-1">
                        <p className="h-fit m-auto text-xs">Earned</p>
                        <p className="text-xs md:text-base h-full text-center">{achievements.filter((entry) => entry.unlocked).length}/{achievements.length}</p>
                    </div>
                    <div className="flex justify-evenly items-end text-xs">
                        <img loading="lazy" alt="trophy-img" src="./assets/trophies/3.png" className="w-4 md:w-8 aspect-square"/>
                        <p>{ achievements.filter((entry) => entry.unlocked && entry.rarity === 3).length }</p>
                    </div>
                    <div className="flex justify-evenly items-end text-xs">
                        <img loading="lazy" alt="trophy-img" src="./assets/trophies/2.png" className="w-4 md:w-8 aspect-square"/>
                        <p>{ achievements.filter((entry) => entry.unlocked && entry.rarity === 2).length }</p>
                    </div>
                    <div className="flex justify-evenly items-end text-xs">
                        <img loading="lazy" alt="trophy-img" src="./assets/trophies/1.png" className="w-4 md:w-8 aspect-square"/>
                        <p>{ achievements.filter((entry) => entry.unlocked && entry.rarity === 1).length }</p>
                    </div>
                    <div className="flex justify-evenly items-end text-xs">
                        <img loading="lazy" alt="trophy-img" src="./assets/trophies/0.png" className="w-4 md:w-8 aspect-square"/>
                        <p>{ achievements.filter((entry) => entry.unlocked && entry.rarity === 0).length }</p>
                    </div>
                </div>
            </div>
            <div className="max-h-[30rem] overflow-auto flex flex-col justify-start gap-1 md:gap-2">
                {
                    achievements.filter((entry) => entry.unlocked).length
                        ? (
                            <div className="h-full flex flex-col justify-start gap-1 md:gap-2">
                                <p className="text-xs w-fit">Unlocked</p>
                                <div className="md:w-full h-fit grid grid-cols-3 md:grid-cols-4 justify-start gap-1 md:gap-2 md:gap-4 px-2 md:px-4">
                                {
                                    achievements.map((achievement, index) => {
                                        if(achievement.unlocked) return <AchievementEntry key={index} index={index} achievement={achievement} unlocked={true} />
                                    })
                                }
                                </div>
                            </div>
                        ) : ''
                }
                {
                    achievements.filter((entry) => !entry.unlocked).length
                        ? (
                            <div className="h-full flex flex-col justify-start gap-1 md:gap-2">
                                <p className="text-xs w-fit">Locked</p>
                                <div className="md:w-full h-fit grid grid-cols-3 md:grid-cols-4 overflow-auto justify-start gap-1 md:gap-2 md:gap-4 px-2 md:px-4">
                                {
                                    achievements.map((achievement, index) => {
                                        if(!achievement.unlocked) return <AchievementEntry key={index} achievement={achievement} unlocked={false} />
                                    })
                                }
                                </div>
                            </div>
                        ) : ''
                }
            </div>
            <Gummy id={2} size={'1.75rem'} right={99} />
        </div>
    )
}