import React, { useEffect } from 'react';
import vancleefisland from '../assets/sounds/musics/van-cleef-island.mp3'

class MusicPlayer extends React.Component{
    constructor()  {
        super();
        this.audio = new Audio(vancleefisland)
        this.audio.loop = true;
        const defaultVolume = localStorage.getItem('volume') !== null ? localStorage.getItem('volume') : window.innerWidth <= 768 ? 25 : 25
        this.audio.volume = defaultVolume / 100;
    }
    setVolume = (value) => {
      this.audio.volume = value / 100;
      if(value === 0) {
        this.audio.pause()
      } else {
        if(this.audio.paused) {
          this.audio.play()
        }
      }
    }
    getVolume = () => {
      return this.audio.volume * 100;
    }
    play = () => {
      try {
        this.audio.play();
      } catch(e) {/**/}
    }
}

export const musicPlayer = new MusicPlayer()