import axios from 'axios';
import { useEffect, useState } from "react"
import { Button } from "../components/Button";
//import eventBus from "../components/EventBus";

function Results(props) {
    return(
        <div className='flex flex-col justify-center gap-2 text-center'>
            {
                props.result.boughtAmt + props.result.mintedAmt
                    ? <div className='flex justify-center items-baseline'>
                        <p className='font-mono text-xs'>💸 Bought/Minted {new Intl.NumberFormat('fr-FR').format(props.result.boughtAmt + props.result.mintedAmt)} tokens for {props.result.paid.toFixed(4)} BTC (${new Intl.NumberFormat('fr-FR').format(parseInt(props.result.paid * props.btcUsd))})</p>
                    </div>
                    : ''
            }
            {
                props.result.holding
                    ? <div className='flex justify-center items-baseline'>
                        <p className='font-mono text-xs'>💰 Holds {new Intl.NumberFormat('fr-FR').format(props.result.holding)} tokens worth {props.result.unrealized.toFixed(4)} BTC (${new Intl.NumberFormat('fr-FR').format(parseInt(props.result.unrealizedUsd))})</p>
                    </div>
                    : props.result.boughtAmt + props.result.mintedAmt && props.result.holding
                    ? <div className='flex justify-center items-baseline'>
                            <p className='font-mono text-xs'>🥸 Dumped all his tokens</p>
                        </div>
                        : ''
                    }
            {
                props.result.soldAmt
                ? <div className='flex justify-center items-baseline'>
                        <p className='font-mono text-xs'>Sold {new Intl.NumberFormat('fr-FR').format(props.result.soldAmt)} tokens for {props.result.realized.toFixed(4)} BTC (${new Intl.NumberFormat('fr-FR').format(parseInt(props.result.realized * props.btcUsd))})</p>
                    </div>
                    : props.result.boughtAmt + props.result.mintedAmt && props.result.soldAmt
                        ? <div className='flex justify-center items-baseline'>
                            <p className='font-mono text-xs'>🐻 Sold nothing </p>
                        </div>
                        : ''
            }
            {
                props.result.paperhanded
                    ? <div className='flex justify-center items-baseline'>
                        <p className='font-mono text-xs'>📉 Would be worth {props.result.paperhanded.toFixed(4)} BTC (${new Intl.NumberFormat('fr-FR').format(parseInt(props.result.paperhanded * props.btcUsd))})</p>
                    </div>
                    : ''
            }
            {
                !props.result.boughtAmt && !props.result.mintedAmt && !props.result.holding && !props.result.soldAmt
                    ? <div className='flex justify-center items-baseline'>
                        <p className='font-mono text-xs'>🐻 Nothing found 🐻</p>
                    </div>
                    : ''
            }
        </div>
    )
}

export function Wallet(props) {
    const [address, setAddress] = useState()
    const [result, setResult] = useState()
    const [loading, setLoading] = useState(false)

    const toBtc = (value) => { return value / 100000000 }
    
    async function getData(_address) {
        //if(address === _address) return 
        console.log(_address.length)
        if(_address.length !== 62) return //xxx Invalid addr
        setLoading(_address)
        setLoading(true)
        let done = 0;
        let cursor = '';
        let results = [];
        while(!done) {
            const res = await axios.get(
                `https://api.simplehash.com/api/v0/fungibles/transfers/wallets?chains=bitcoin&wallet_addresses=${_address}&fungible_ids=bitcoin.867080:468&include_fungible_details=0&exclude_self_transfers=1&limit=50${cursor}`,
                {
                    headers: {
                        'X-API-KEY': 'erc1337coffe_sk_8771p1zn80rhbqp4yi0wszambkcxib0v'
                    }
                }
            )
            results.push(res.data.transfers)
            if(res.data?.next_cursor) {
                cursor = `&cursor=${res.data.next_cursor}`
            } else {
                done = true
            }
        }
        results = results.flat(1);
        console.log(results)
        const data = { mintedAmt: 0, boughtAmt: 0, soldAmt: 0, sent: 0, received: 0, paid: 0, realized: 0, unrealized: 0, faded: [], paperhanded: 0 }
        for (const res of results) {
            if(res.event_type === 'transfer') {
                if(res.from_address === _address.toLowerCase()) {
                    data.sent += res.quantity
                } else {
                    data.received += res.quantity
                }
            } else if(res.event_type === 'mint') {
                data.mintedAmt += res.quantity
            } else if(res.event_type === 'sale') {
                const unitPrice = parseFloat(res.sale_details.total_price) / res.quantity
                if(res.from_address === _address.toLowerCase()) {
                    // Sold
                    data.soldAmt += res.quantity
                    data.realized += toBtc(res.sale_details.total_price)
                    data.faded.push({ quantity: res.quantity, unitPrice: unitPrice })
                } else {
                    // Bought
                    data.boughtAmt += res.quantity
                    data.paid += toBtc(res.sale_details.total_price)
                }
            }
        }
        
        const res = await axios.get('https://api.simplehash.com/api/v0/fungibles/lookup?chains=bitcoin&name=BITCOIN%E2%80%A2BRO%E2%80%A2BEAR&include_prices=1',
            { headers: { 'X-API-KEY': 'erc1337coffe_sk_8771p1zn80rhbqp4yi0wszambkcxib0v' } }
        )
        const rune = res.data.fungibles.filter((entry) => entry.fungible_id === 'bitcoin.867080:468').pop()
        const actualPrice = parseFloat(rune.prices[0].value_usd_string) * 100
        const actualPriceSats = actualPrice / props.btcUsd * 1000000
        data.holding = (data.received + data.boughtAmt + data.mintedAmt - data.soldAmt - data.sent) / 100
        data.unrealizedUsd = data.holding * actualPrice
        data.unrealized = data.unrealizedUsd / props.btcUsd
        data.totalFaded = 0;
        for(const fade of data.faded) {
            fade.realized = toBtc(fade.quantity * fade.unitPrice) * props.btcUsd
            fade.potential = toBtc(fade.quantity * actualPriceSats) * props.btcUsd
            fade.missed = fade.potential - fade.realized       
            data.paperhanded += fade.missed / props.btcUsd
            data.paperhandedUsd += fade.missed
        }
        console.log('********')
        console.log(data)
        setResult({...data})
        setLoading(false)
    }
    
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            getData(e.target.value).catch(console.error)
        }      
    }

    useEffect(() => {}, [address, loading, result])
    /*
    const handlerAchievement = () => {
        eventBus.dispatch("unlock", { id: "savage" });
        }
        */
       return(
        <div className="z-30 h-full min-w-[25rem] flex flex-col md:flex-row justify-center">
            <div className="w-full p-2 md:p-4 flex flex-col justify-center items-center gap-2 md:gap-2">
                <h1 className="w-fit font-bold text-sm md:text-lg text-center">Paperhand Calculator</h1>
                <p className="w-fit text-xs md:text-sm text-center"></p>
                <div className="w-full flex flex-col gap-2">
                    <p className="w-fit text-xs md:text-sm text-center">Enter an address:</p>
                    <div className="w-full justify-between items-center flex gap-1">
                        <input type="text" onChange={(e) => { setAddress(e.target.value) }} onKeyDown={handleKeyDown} className="flex-auto p-2 rounded-lg bg-white/10 text-xs" />
                        {
                            loading
                                ? ''
                                : <Button 
                                    text={'Check'}
                                    handler={() => { getData(address).catch(console.error) }}
                                    color={'#2860D3'}
                                />
                        }
                    </div>
                </div>
                {
                    loading
                        ? <div className='flex justify-center items-center gap-2'><p className='text-xs font-mono font-bold'>Loading...</p><img loading="lazy" src="./assets/cursor.png" alt="loading-img" className={`h-8 animate-spin`} draggable={false} /></div>
                        : result 
                            ? <Results result={result} btcUsd={props.btcUsd} /> 
                            : ''
                }
            </div>
        </div>
    )
}