import { useEffect, useState } from "react";
import App from "./App";
import { LandingPage } from "./LandingPage";

export function Wrapper(props) {
    const [state, setState] = useState(0);
    const [child, setChild] = useState(0);

    useEffect(() => {
        if(!state) return
        setTimeout(() => {
            setChild(1)
        }, 5000)
    }, [state])
    if(child) {
        return <App state={state} />
    } else {
        return <LandingPage state={state} setState={setState} />
    }

}