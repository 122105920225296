import { useEffect, useState } from "react";
import { musicPlayer } from "./components/MusicPlayer"

function Lambo(props) {
    useEffect(() => {}, [props.state])
    return(
        <div className={`z-30 relative m-auto inset-y-0 inset-x-0 w-[100vw] h-[100vh] ${props.state ? "animate-carMoveIn" : ""} car`}>
            <img alt="car" src="./assets/landing/welcome.png" className={`z-[33] absolute inset-y-0 inset-x-0 m-auto opacity-0 ${props.state ? "animate-welcomeBubble" : ""}`} />
            <img alt="car" src="./assets/landing/door.png" className={`z-[32] absolute inset-y-0 inset-x-0 m-auto ${props.state ? "animate-carDoorOpen" : ""}`} />
            <img alt="car" src="./assets/landing/car.png" className={`z-[31] absolute inset-y-0 inset-x-0 m-auto`} />
            <img alt="car" src="./assets/landing/back.png" className={`z-[31] absolute inset-y-0 inset-x-0 m-auto`} />
            <img alt="car" src="./assets/landing/front.png" className={`z-[31] absolute inset-y-0 inset-x-0 m-auto`} />
        </div>
    )
}

export function LandingPage(props) {
    const [volState, setVolState] = useState()

    const handleClick = () => {
        if(!props.state) {
            musicPlayer.play()
            props.setState(1)
        }
    }

    useEffect(() => {
        const actualVolume = parseInt(localStorage.getItem('volume')) ? true : false
        setVolState(actualVolume);
    }, [])

    useEffect(() => {
    }, [volState])

    return(
        <div className={`relative z-10 w-full object-center overflow-hidden select-none flex justify-center items-center`}>
            <img draggable={false} src="./assets/bg.png" alt="bg" className={`pointer-events-none absolute m-auto left-0 top-0 right-0 bottom-0 h-[100vh] w-[100vw] z-[99] brightness-0 opacity-0 ${props.state ? "animate-opacityIn" : ""}`}/>
            <img draggable={false} src="./assets/landing/1.png" className="transition-all transform-gpu duration-100 ease-out animate-para1 absolute w-full h-[150%] mt-[-25%] z-[11]"/>
            <img draggable={false} src="./assets/landing/2.png" className="transition-all transform-gpu duration-100 ease-out animate-para2 absolute w-full h-[150%] mt-[-25%] z-[12]"/>
            <img draggable={false} src="./assets/landing/3.png" className="transition-all transform-gpu duration-100 ease-out animate-para3 absolute w-full h-[150%] mt-[-25%] z-[13]"/>
            <div onClick={handleClick} className={`p-2 rounded-lg hover:bg-white/10 active:bg-white/20 pointer z-30 absolute m-auto left-0 top-0 right-0 bottom-0 text-center h-fit w-fit group transition-all transform-gpu duration-100 ease-out ${props.state ? "animate-bounce" : ""}`}>
                <p className="z-30 neon m-auto font-['Andale'] font-bold text-5xl text-white/80 hover:text-white/90 active:text-white">ENTER</p>
            </div>
            <Lambo state={props.state} />
            <div className="z-[99] m-auto absolute left-0 right-0 bottom-20 md:bottom-0 flex justify-center items-center gap-4">
                <img draggable={false} src="./assets/landing/warning.png" className="h-24 md:h-32"/>
                <p className="text-white md:font-bold text-center text-xs md:text-sm">Warning: { volState ? "There's music on this website" : "You muted the music bro" }</p>
            </div>
        </div>
    )
}