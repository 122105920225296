import { useEffect, useState } from "react"
import { musicPlayer } from "./MusicPlayer"
import eventBus from "../components/EventBus";

export function VolumeSlider(props) {
    const [clicking, setClicking] = useState(false)
    const [volume, setVolume] = useState(0)

    const handleClick = (e) => {
        setClicking(e)
    }

    const handleVolumeSelect = (e) => {
        let pct;
        if(window.innerWidth <= 768) {
            pct = volume ? 0 : 100
        } else {
            if(!clicking) return
            try {
                const parent = document.getElementById("volume-slider").getBoundingClientRect()
                const pStart = parent.x
                const pEnd = parent.x + parent.width
                const divSize = pEnd - pStart
                const userClick = e.clientX - pStart
                pct = Math.round(userClick * 100 / divSize)
            } catch(e) {
                pct = 0;
            }
        }
        if(pct <= 20) {
            pct = 0
        } else if(pct >= 90) {
            pct = 100
            eventBus.dispatch("unlock", { id: "vibing" });
        }
        musicPlayer.setVolume(pct)
        setVolume(pct)
    }
    
    useEffect(() => {
        const pct = musicPlayer.getVolume();
        setVolume(pct)
    }, [])
    
    useEffect(() => {
        localStorage.setItem('volume', volume)
    }, [volume])

      
    return(
        <div 
            id="volume-slider"
            className={`
                z-[32]
                absolute 
                top-6
                right-4 md:right-16
                rounded-xl overflow-hidden
                pointer
                drop-shadow-lg
                bg-white/10 
                md:backdrop-blur-lg
                h-6 w-4/12 md:w-1/12
                flex items-center
            `}
            onMouseMove={handleVolumeSelect}
            onMouseDown={() => { handleClick(true) }}
            onMouseUp={() => { handleClick(false) }}
        >
            <img draggable={false} alt="sound-icon" src="./assets/sound.png" className="aspect-square h-full absolute left-0 p-1.5 z-[33] pointer-events-none" />
            <div className="pointer-events-none h-full bg-white/80 rounded-xl transition-all transform-gpu ease-out duration-100" style={{ width: `${volume}%` }}></div>
        </div>
    )
}