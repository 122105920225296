import { useEffect } from 'react';
import { motion } from "framer-motion"
import { Window } from './Window.js';
import { Home } from '../pages/Home.js';
import { PfpGenerator } from '../pages/Pfp.js';
import { Achievements } from '../pages/Achievements.js';
import { Mail } from '../pages/Mail.js';
import { Wallet } from '../pages/Wallet.js';
import { Stocks } from '../pages/Stocks.js';
import { Wechat } from '../pages/Wechat.js';


export function Content(props) {
    useEffect(() => {}, [props.menu])
    if(!props.menu) return
    return(
        <motion.div
            drag={window.innerWidth >= 768} dragSnapToOrigin={true}
            className="m-auto z-50 flex justify-center items-center" 
            style={{ 
                touchAction: "none"
            }}
        >
            <Window 
                title={''} 
                className="h-auto w-auto flex justify-center items-center z-29 "
                setMenu={props.setMenu}
            >
            {
                props.menu === 'home'
                    ? <Home />
                    : props.menu === 'pfp'
                        ? <PfpGenerator />
                        : props.menu === 'mail'
                            ? <Mail />
                            : props.menu === 'wallet'
                                ? <Wallet btcUsd={props.btcUsd} />
                                : props.menu === 'stocks'
                                    ? <Stocks />
                                    : props.menu === 'achievements'
                                        ? <Achievements />
                                        : props.menu === 'wechat'
                                            ? <Wechat />
                                            : ''
            }
            </Window>
        </motion.div>
    )
}  

